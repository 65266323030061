#churn-rate-predictor {
  .form-controls {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding-top: 25px;

    & > * {
      width: 47%;
      //Below selector is for form-control

      & > * {
        align-items: center;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        margin: 10px 5px;
        // background-color: red;
        & > * {
          margin: 0;
        }
        & > *:first-child {
          width: 40%;
        }
        & > *:last-child {
          width: 60%;
        }
        & .radio-buttons {
          display: flex;
          & > * {
            width: 50%;
          }
          width: 60%;
        }
        & .MuiFormGroup-root {
          display: flex;
          flex-direction: row;
          & > * {
            width: 50%;
            margin: 0;
          }
        }
      }
    }

    fieldset {
      border: none;
    }
  }

  .has-slider {
    display: flex;
    & > *:first-child {
      width: 40%;
    }
    & > *:nth-child(2) {
      width: 40%;
    }
    & > *:nth-child(3) {
      width: 100px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 4px;
    }
  }
  .radio-name {
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  .slider-value {
    outline: none;
    border: none;
  }
  .form-control > span:first-child {
    font-weight: bold;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    & > * {
      padding: 5px 15px;
      display: inline-block;
      width: 200px;
      margin: 20px;
      font-size: 16px;
      text-align: center;
      font-weight: bold;
      border-radius: 4px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }
    }

    .predict {
      background-color: #416c6a;
      color: white;
    }
    .random-values {
      background-color: #bfd7d0;
      &.secondary {
        background-color: #7aa89c;
        color: white;
      }
    }
  }
}

.churn-risk-result {
  background-color: white;
  padding: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  & > *:nth-child(2) {
    margin-bottom: 15px !important
    ;
  }
  & > * {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    text-align: center;
  }
  .standard-primary-button {
    margin: auto;
  }
  h1 {
    color: #4a937e;
  }
}

@media (max-width: 1000px) {
  #churn-rate-predictor {
    .form-controls {
      flex-direction: column;
      align-items: center;
      & > * {
        width: 90%;
      }
    }
  }
}
@media (max-width: 500px) {
  #churn-rate-predictor {
    .radio-name {
      font-size: 0.7rem !important;
    }
    .form-control > span:first-child {
      font-size: 0.7rem !important;
    }
    .MuiSelect-select {
      font-size: 0.7rem;
    }
    .radio-buttons {
      font-size: 0.7rem;
    }
  }
}
