.standard-primary-button {
  cursor: pointer;
  color: white;
  font-weight: bold;
  background-color: #416c6a;
  width: 200px;
  height: 40px;
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color-primary {
  color: #4a937e;
}
.color-red {
  color: red;
}

.youtubu-container{
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
