.demo-item-header {
  height: 80px;
  width: min(100%, 1520px);
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  .item-back-button {
    border-radius: 4px;
    width: 120px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    padding: 13px 17px;
    background-color: #7aa89c;
    color: white;
    height: 40px;

    & > * {
      margin-right: 5px;
    }
    .item-text {
      padding-bottom: 2px;
    }
  }
  .item-back-button:hover {
    cursor: pointer;
  }
  p {
    padding-left: 50px;
    font-size: 2rem;
    text-align: start;
    flex-grow: 1;
  }
  .model-details-trigger:hover {
    cursor: pointer;
  }
  .model-details-trigger {
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    color: #416c6a;
    display: flex;
    align-items: center;
    img {
      padding-right: 15px;
    }
    justify-content: space-between;
    // text-under
  }
  .bikes-date {
    display: flex;
    align-items: center;
  }
  .date-label {
    font-weight: bold;
    margin-right: 20px;
  }

  .date-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .date-label {
      font-weight: bold;
      margin-right: 20px;
    }

    .date-description {
      margin-right: 20px;
    }
     
  }
}

@media (max-width: 1350px) {
  .demo-item-header {
    p {
      font-size: 1.65rem;
    }
    .date-label {
      font-size: 0.9rem;
    }
  }
}

@media (max-width: 975px) {
  .demo-item-header {
    padding: 10px;
    p {
      padding: 15px;
      font-size: 1.2rem;
    }
    .item-back-button {
      width: auto;
      padding: 10px 15px;
    }
    .item-text {
      display: none;
    }
    .date-label {
      font-size: 0.8rem;
    }
  }
}
@media (max-width: 580px) {
  .demo-item-header {
    padding: 10px;
    p {
      padding: 15px;
      font-size: 1rem;
    }
    .item-back-button {
      padding: 10px 15px;
    }
    .item-text {
      display: none;
    }
    .date-label {
      font-size: 0.7rem;
    }
  }
}
