.data-centric-container {
  margin: 0px 30px;
  .header-container {
    background-image: url("../assets/images/dataCentric/header.png");
    background-size: 100% 100%;
    height: 130px;
    padding: 20px 4.5rem;

    .label-header {
      position: absolute;
      top: -40px;
      font-size: 25px;
      font-weight: 400;
      width: 100px;
      text-align: center;
      color: #637E81 ;
      font-weight: 400;
    }

    .label-footer {
      position: absolute;
      top: 30px;
      font-size: 14px;
      font-weight: 600;
      width: 300px;
      text-align: center;
    }

    .RSPBprogressBar {
      margin-top: 45px;
      height: 8px;
      background-color: #E3E3E3;
      .RSPBprogression {
        height: 4px;
        left: 2px;
        top: 2px;
        background-color: #14353C;
      }

      .RSPBstep {
        &:first-child {
          left: 10% !important;
        }

        &:nth-child(3) {
          left: 90% !important;
        }

        .indexedStep {
          background: var(--color-white);
          border: 3px solid #789091;
          cursor: pointer;
          .accom-circle {
            width: 10px;
            height: 10px;
            background: #789091;
            border-radius: 50%;
          }
        }
      }
    }
  }

  .indexedStep {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .indexedStep.accomplished {
    background-color: rgba(0, 116, 217, 1);
  }
}
