#model-chart {
  box-sizing: border-box !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.3rem;
  // position: absolute;
  background-color: white;
  width: 100%;
  padding: 0;

  *:not(.white-markers) {
    opacity: 0.999;
    z-index: 2;
  }

  h3,
  .row {
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 30px;
    width: 100%;
    margin: auto;

    .bar-container {
      // background-color: rgba(255, 37, 37, 0.062);
      width: 50%;
      .bar {
        height: 100%;
        width: 33%;
        background-color: #7aa89c;
      }
    }
    .range {
      position: absolute;
    }
  }
  & > :nth-child(2n) {
    background-color: #f9f9f9;
  }
  & > :nth-child(2n-1) {
    background-color: #f2f2f2;
  }
  & > :first-child {
    font-weight: normal;
    text-align: center;
    justify-content: center;
    font-size: 1.3rem;
    align-content: center;
    display: flex;
    align-items: center;
    background-color: #fff;
    text-align: center;
  }
  .row,
  h3 {
    margin: 0;
  }
  .row-with-values {
    margin-top: 5px;
    position: relative;
    background-color: white !important;
    color: black;
    * {
      position: absolute;
    }
    *:first-child {
      left: 50%;
    }
    *:nth-child(2) {
      left: 63%;
    }
    *:nth-child(3) {
      left: 76%;
    }
    *:nth-child(4) {
      left: 89%;
    }
  }
  .white-markers {
    position: absolute;
    right: 0;
    width: 50%;
    z-index: 0;
    // background-color: blue;
    height: 100%;
    * {
      background-color: white;
      position: absolute;
      height: 100%;
      width: 3px;
    }
    *:first-child {
      left: 0%;
    }
    *:nth-child(2) {
      left: 26%;
    }
    *:nth-child(3) {
      left: 56%;
    }
    *:nth-child(4) {
      left: 79%;
    }
  }
}

#model-chart {
  .range {
    width: 57%;
    height: 100%;
    // background-color: blanchedalmond;
    right: 0%;
    // transform: translateX(-50%);
    display: flex;
    .horizontal-bar {
      // width: 25%;
      align-self: center;
      height: 2px;
      background-color: rgba(83, 83, 83, 0.39);
    }
    .left-handle,
    .right-handle {
      height: 100%;
      width: 2px;
      background-color: rgb(92, 92, 92);
    }
  }
}

// #model-details {
.footer-items {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 10px 0px 0px;
  align-items: center;
  .button {
    width: 200px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    align-self: center;
    background-color: #416c6a;
    color: white;
    cursor: pointer;
    // padding: 10px 80px;
  }
  .equation {
    font-size: 1.5rem;
    font-weight: bold;
    sub {
      color: #7aa89c;
      font-weight: normal;
    }
  }

  .more-info {
    margin-left: auto;
    margin-right: auto;
    
  }
}
// }

@media (max-width: 850px) {
  .model-chart-container {
    padding: 25px !important;
    .row p {
      font-size: 0.9rem;
    }
  }
  .footer-items {
    padding: 10px 25px 20px;

    .button {
      width: 150px;
    }
    .equation {
      font-size: 1.2rem;
      font-weight: bold;
      sub {
        color: #7aa89c;
        font-weight: normal;
      }
    }
  }
}
@media (max-width: 500px) {
  #model-chart {
    h3 {
      font-size: 1rem !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.model-chart-container {
  // width: 100%;
  h1 {
    // padding: 0 15px;
    margin-bottom: 20px;
  }
  height: 100%;
  padding: 70px;
  width: min(80vw, 780px);
  max-width: min(80vw, 780px);
  position: relative;
}
