#left-panel {
  // padding-top: 80px;
  background-color: #789091;
  width: 240px;
  min-width: 240px;
  height: calc(100vh - 80px);
  color: white;
  transition: none;
  padding-top: 80px;
  & > div:first-child {
    margin-top: 0;
  }
  & > div {
    width: 220px;
    display: flex;
    flex-direction: column;
    margin: 8px 0;
    // margin-bottom: 43px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 50px;
    height: 80px;
    justify-content: center;
    user-select: none;
    &:hover {
      cursor: pointer;
    }
    // transition: 0.3s;
    img {
      width: 24px;
      height: 24px;
      margin-bottom: 5px;
    }
    div {
      text-align: start;
      font-size: 18px;
      padding: 0px 0px 5px;
      padding-left: 0;
      font-weight: 700;
    }
  }
  & > div:hover {
    background-color: #25484C;
  }
  & > div.active-list-view {
    border-right: 5px solid #bfd7d0;
    background-color: #25484C;
  }
}

@media (max-width: 1000px) {
  #left-panel {
    padding-top: 50px;
  }
}
@media (max-width: 750px) {
  #left-panel {
    padding-top: 25px;
  }
}

@media (max-width: 675px) {
  #left-panel {
    width: 50px;
    min-width: 50px;
    & > div {
      width: 100%;
      padding: 0;
      img {
        display: block;
        margin: auto;
      }
    }
    .item-name {
      display: none !important;
    }
  }
}
