#property-price-indicator {
  margin-top: 50px;
  justify-content: center;
  display: grid;
  grid-template-columns: min(49%, 740px) min(49%, 740px);
  height: 240px;
  grid-gap: 30px;
  .property-item {
    padding: 20px;
    background-color: white;
    width: min(720px, 100%);
    margin: auto;
    height: 240px;
    display: grid;
    grid-template-columns: repeat(10, 10%);
    grid-template-rows: repeat(10, 10%);
    .property-image {
      padding-right: 5px;
      height: 100%;
      width: min(100%, 260px);
      object-fit: cover;
      grid-row: 1 / 11;
      grid-column: span 4;
      justify-self: start;
    }
    .features {
      display: flex;
      grid-column: span 6;
      grid-row: 4 / 6;
      align-items: center;
      justify-content: space-between;
      & > *:first-child {
        display: flex;
        align-items: center;
      }
      .label {
        margin-left: 3px;
      }
      img {
        margin-right: 3px;
      }
    }
    .name {
      font-weight: normal;
      margin: 0;
      grid-row: 1;
      grid-column: span 5;
      height: 100%;
      width: 100%;
      color: #7aa89c;
      font-size: 2.5rem;
      padding: 5px;
    }
    .location {
      grid-row: 7 / span 1;
      grid-column: span 2;
      justify-content: center;
      padding: 5px;
      font-weight: bold;
    }
    .price {
      grid-row: 7 / span 1;
      grid-column: 8 / 11;
      justify-self: center;
      align-items: center;
      font-size: 30px;
    }
    .price-indicator-bar {
      width: 100%;
      grid-column: span 6;
      grid-row: 10 / span 1;
      align-items: center;
      background: linear-gradient(
        90deg,
        #c2e19a 0%,
        #fff59c 50.52%,
        #ffafaf 99.48%
      );
      position: relative;
      .marker {
        height: 100%;
        width: 5px;
        background-color: black;
        position: absolute;
        top: 0;
        left: 50%;
      }
    }
    .align-left,
    .align-right {
      text-transform: uppercase;
      font-weight: bold;
    }
    .align-left {
      text-align: left;
    }
    .align-right {
      text-align: right;
    }
  }
}

@media (max-width: 1700px) {
  #property-price-indicator {
    .property-item {
      padding: 10px;
      .features {
        padding: 5px;
      }
    }
    .name {
      font-size: 2rem !important;
    }
    .price {
      font-size: 24px !important;
    }
  }
}

@media (max-width: 1500px) {
  #property-price-indicator {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

@media (max-width: 950px) {
  #property-price-indicator {
    .price {
      font-size: 22px !important;
    }
    .location {
      font-size: 0.85rem;
    }
    .price-indicator-bar {
      font-size: 0.7rem !important;
    }
  }
}

@media (max-width: 800px) {
  #property-price-indicator {
    .property-item {
      padding: 5px;
      .price {
        font-size: 17px !important;
      }
    }
    .features {
      font-size: 0.7rem;
    }
  }
}
@media (max-width: 500px) {
  #property-price-indicator {
    .name {
      font-size: 1.7rem !important;
    }
    .label {
      display: none;
    }
  }
}
