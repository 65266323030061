#main-view {
  padding-top: 80px;
  width: 0;
  background-color: #f2f2f2;
  flex-grow: 1;
  height: calc(100vh - 80px);
  overflow: auto;

  &.homepage {
    padding: 10px;
    background-color: #222e3c;
  }
  &.white-bg {
    background-color: var(--color-white);
  }
}

.plus-minus-btton {
  position: relative;
  .input {
    width: 140px;
    height: 40px;
    border-radius: 4px;
  }

  .minus-button {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 10px;
    top: 10px;
    background: #f2f2f2;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #416c6a;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
    }
  }

  .plus-button {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 110px;
    top: 10px;
    background: #f2f2f2;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #416c6a;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
    }
  }
}

@media (max-width: 1000px) {
  #main-view {
    padding-top: 50px;
  }
}
@media (max-width: 750px) {
  #main-view {
    padding-top: 25px;
  }
}
