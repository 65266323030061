#bikes-demand-forecast {
  margin-top: 40px;
  .table {
    max-height: 530px;
  }
  .table-head-row * {
    font-weight: bold;
    color: #416c6a;
  }
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    .button {
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 40px;
      color: white;
      background-color: #416c6a;
      &:hover {
        cursor: pointer;
      }
      border-radius: 4px;
    }
    .model-button {
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 40px;
      background-color: white;
      &:hover {
        cursor: pointer;
      }
      border-radius: 4px;
      margin-right: 15px;
    }

    .btn-container {
      & > *:first-child {
        color: white;
        background-color: #7aa89c;
      }
      display: flex;
      justify-content: space-between;
    }
  }
}

.bikes-model-details {
  padding: 70px;
  & .row {
    h1 {
      font-size: 30px;
    }
    display: flex;
    justify-content: space-between;
    .labels {
      display: flex;
      flex-direction: row;
      .label {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 10px;
        font-weight: bold;
      }
      .line {
        margin: 5px;
        height: 0px;
        width: 60px;
        border: 2px solid #bfd7d0;
        background-color: #bfd7d0;
      }
      .line-with-circle {
        border: 2px solid #416c6a;
        background-color: #416c6a;
        display: flex;
        justify-content: center;
        align-items: center;
        .circle-center {
          width: 7px;
          height: 7px;
          border-radius: 100%;
          background-color: #416c6a;
        }
      }
    }
  }

  .standard-primary-button {
    display: block;
    margin: auto;
  }
  img {
    margin: 20px auto;
  }
}

.bikes-predict-result {
  background-color: white;
  padding: 50px;
  text-align: center;

  & > * {
    margin: 20px auto;
    text-align: center;
  }
  .use-case-button {
    background-color: #416c6a;
    display: inline-block;
    color: white;
    padding: 5px 20px;
    width: 150px;
    border-radius: 4px;
    margin: auto;
    cursor: pointer;
  }
  .result {
    color: #4a937e;
  }
}

// .css-1ozefeb {
//   // background-color: red !important;
//   transform: scale(3);
//   :nth-child(2) > * > .MuiSvgIcon-root {
//     // color: white !important;
//     display: none;
//     background-color: red !important;
//   }
// }
