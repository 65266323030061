#demo {
  width: 100%;
  min-height: 100%;
  padding: 0 80px;
  display: grid;
  grid-template-columns: min(45%, 740px) min(45%, 740px);
  grid-auto-rows: 180px;
  grid-gap: 40px;
  grid-column-gap: 30px;

  & > div:first-child {
    margin-top: 0 !important;
  }

  & > * {
    width: 100%;
  }
  .demo-item {
    padding: 7px;
    background-color: white;
    height: 180px;
    width: 100%;
    border: 3px solid #7aa89c;
  }
  .demo-item div {
    overflow: hidden;
    position: relative;
    display: grid;
    height: 100%;
    grid-template-columns: 25% 75%;
    grid-template-rows: 33% 33% 34%;
    width: 100%;
    h2 {
      color: #7aa89c;
      font-size: 36px;
      font-weight: normal;
      grid-row: span 2;
      align-self: center;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    & > svg {
      width: 80px;
      height: 80px;
      grid-row: span 3;
      justify-self: center;
      align-self: center;
    }
    & > *:not(svg) {
      justify-self: start;
    }
    .demo-subtitle {
      margin: 0;
      text-transform: uppercase;
      align-self: center;
    }
    .background-image {
      position: absolute;
      right: 0;
      top: 35px;
      height: 180px;
      width: 180px;
      opacity: 0.05;
      & > * {
        height: 180px;
        width: 180px;
      }
    }
  }

  .demo-item:hover {

    & > * {
      cursor: pointer;
      background-color: #7aa89c;
      h2 {
        color: white;
      }
      & > svg *[fill="#637E81"] {
        fill: white;
      }
      .background-image svg *[fill] {
        fill: black;
      }
      .background-image svg {
        opacity: 0.4;
      }
    }
  }
}

.demo-view-container {
  width: min(100%, 1520px);
  // margin: auto;
  // height: 920px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  #demo {
    padding: 40px;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 600px) {
  #demo {
    padding: 15px;
  }
}
