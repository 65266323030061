#canner-type-id {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 3rem;
  grid-template-columns: repeat(4, 128px);
  grid-auto-rows: 128px;
  grid-gap: 30px;
  .type-image {
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    &:hover {
      border: 4px solid #7aa89c;
      cursor: pointer;
    }
  }
  img {
    width: 128px;
  }
}
#galaxy-type-id {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 3rem;
  grid-template-columns: repeat(4, 128px);
  grid-auto-rows: 128px;
  grid-gap: 30px;
  .type-image {
    width: 140px;
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;

    &:hover {
      border: 4px solid #7aa89c;
      cursor: pointer;
    }
  }
  img {
    width: 128px;
  }
}
.galaxy {
  text-align: center;

  .galaxy-result {
    font-size: 30px;
    text-align: center;
    font-weight: 700;
    line-height: 40.85px;
  }

  .galaxy-predict {
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    line-height: 68.09px;
    color: #4a937e;
  }

  .galaxy-button {
    padding: 5px 15px;
    display: inline-block;
    width: 200px;
    margin: 20px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    border-radius: 4px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #416c6a;
    color: white;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }
}
.color-red {
  color: red !important;
}
@media (max-width: 950px) {
  #galaxy-type-id {
    margin-top: 50px;

    grid-template-columns: repeat(3, 128px);
  }
}
@media (max-width: 750px) {
  #galaxy-type-id {
    grid-template-columns: repeat(3, 110px);
    grid-auto-rows: 110px;
  }
}
@media (max-width: 500px) {
  #galaxy-type-id {
    margin-top: 25px;

    grid-template-columns: repeat(2, 128px);
    grid-auto-rows: 128px;
  }
}
