#housing-price-prediction {
  position: relative;
  flex-grow: 1;

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 70px;

    .random-values {
      display: block;
      width: 200px;
      text-align: center;
      color: white;
      border-radius: 5px;
      margin-left: auto;
      padding: 5px 20px;
      right: 0px;
      cursor: pointer;
      border-radius: 4px;
      background: #7aa89c;
      margin-right: 20px;
      font-weight: 600;
    }
  }
  form {
    margin-top: 80px;
    display: flex;
    justify-content: space-around;
    & > * {
      width: 46.5%;
      // .form-control:not(*:last-child) {
      //   border-bottom: 0.5px solid #1f2e3c32;
      //   padding-bottom: 8px;
      // }
      .form-control {
        .MuiSelect {
          border-radius: 4px;
        }
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px;
        .slider-value {
          font-weight: normal;
          border: none;
          height: 40px;
          border-radius: 4px;
        }
        span {
          font-size: 1.3rem;
          font-weight: bold;
        }

        fieldset {
          border: none;
        }
      }
    }
  }
  .slider-value {
    background-color: white;
    padding: 5px;
    text-align: center;
    font-weight: normal;
    // text-align: center;
    display: flex;
    justify-content: center;
  }

  .button-details {
    margin-left: auto;
    font-weight: bold;
    text-align: end;

    margin-top: 70px;
  }
  .submit-form-button-1 {
    background-color: #416c6a;
    display: block;
    width: 200px;
    text-align: center;
    color: white;
    border-radius: 5px;
    margin-left: auto;
    margin-top: 10px;
    padding: 8px 20px;
    right: 0px;
    cursor: pointer;
    vertical-align: middle;
    border-radius: 4px;
  }

  .submit-form-button {
    background-color: #416c6a;
    display: block;
    width: 200px;
    text-align: center;
    color: white;
    border-radius: 5px;
    padding: 5px 20px;
    right: 0px;
    cursor: pointer;
    border-radius: 4px;
    font-weight: 600;
  }

  .has-select {
    display: flex;
    align-items: center;
    & > *:first-child {
      width: 40%;
    }
    & > *:last-child {
      width: 60%;
    }
  }
  .has-slider {
    & > *:first-child {
      width: 40%;
    }
    & > *:nth-child(2) {
      width: 30%;
      margin-right: 10%;
    }
    & > *:last-child {
      width: 20%;
    }
  }
}

.housing-prediction-result {
  background-color: white;
  padding: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    text-align: center;
  }

  & > *:nth-child(2) {
    margin-bottom: 15px !important
    ;
  }

  .standard-primary-button {
    margin: auto;
  }
  h1 {
    color: #4a937e;
  }
}

@media (max-width: 1350px) {
  #housing-price-prediction {
    form {
      & > * {
        width: 49.5%;
      }
      .form-control {
        span {
          font-size: 1.1rem;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  #housing-price-prediction {
    form {
      flex-direction: column;
      align-items: center;
      & > div {
        width: 90%;
      }
    }
  }
}
@media (max-width: 450px) {
  .housing-prediction-result {
    padding: 15px;
    h2 {
      font-size: 1.3rem;
    }
    h1 {
      font-size: 1.5rem;
    }
  }
}
