.header-descriptions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.covid-actions {
  width: 100%;
}

#covid-details {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  .table-cont {
    width: 48%;
  }

  img {
    width: 100%;
  }
}

.covid-details-table {
  max-height: 700px;
}

.covid-hospitalizations-prediction {
  .model-details-button {
    user-select: none;
    cursor: pointer;
  }
}
