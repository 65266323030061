#header {
  width: 100%;
  height: 80px;
  background-color: #25494C;
  color: white;
  display: flex;
  align-items: center;
  padding-left: 40px;
  margin: auto;
  img {
    height: 50px;
    cursor: pointer;
  }
  span {
    font-size: 2rem;
    font-weight: bold;
  }
}
